<template>
    <div class="hd">
      <div @click="back">返回</div>
      <div >
<!--        <md-icon name="search" size="mini"/>-->
<!--        <input  type="text" placeholder="输入您要搜索的内容" v-model="keyWord">-->
      </div>
    </div>
</template>

<script>
export default {
name: "Hd",
  data(){
    return {
      keyWord:''
    }
  },
  watch:{
    keyWord(nv){
        this.$emit('search',nv)
     }
  },
  methods:{
    back(){
      if (window.history.length <= 1) {
        this.$router.push({path:'/'})
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
   .hd{
     background: #efefef;
     height: 100px;
     display: flex;
     justify-content: space-between;
     position: relative;
     align-items:center;
     padding:0 25px;
     font-size: 26px;
     z-index: 1999;
     input{
       font-size: 24px;
       padding:10px;
       line-height: 2;
       outline: none;
       border:none;
     }
     .search{
       background: #fff;
       padding-left: 10px;
       border-radius: 20px;
       overflow: hidden;
       height: 75px;
       line-height: 75px;
     }
   }


</style>
