<template>
  <keep-alive include="List,Home">
    <router-view />
  </keep-alive>
</template>

<script>
export  default {
   data(){
       return {

       }
   },
  created(){
    let iswx = this.isWeiXin();

    console.log('window.location.hash',window.location.hash.split("?")[0])
    if(iswx){
      // 微信浏览器流程

      setTimeout(()=> {
        const { unionid } =  this.$route.query
        if(unionid){
          console.log('获取用户信息')
          this.getUserInfo()
        }else{
          // 第一步先查看是否有 unionId
          const {  userInfo  } = this.$store.state
          const backurl = this.$store.state.gotoUrl;
          console.log('backurl',backurl)
          console.log(window.location.hash)
          if(!userInfo.unionid){
            //如果没有就要跳转到授权页面  跳转前先保存当前访问的链接
            if(!window.location.hash.includes('welcom')) {
              console.log('设置url',window.location.hash)
              this.$store.commit('seturl',window.location.hash)
            }


            const redirecturl = encodeURIComponent('http://www.wzsqyg.com/wxforwz')
            const appid = 'wx8600dc0dd3780ffc';
            const baseurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirecturl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
           window.location.href = baseurl

          }else{
            this.getUserInfo()
          }
        }

      },100)



    }else{
      // 请使用微信浏览器打开;



    }
  },
  mounted(){




  },
  methods:{
     async getUserInfo(){
       let u = await this.$api.post('/mapi/getUserInfo',{ unionid: this.$route.query.unionid})

       console.log('u',u)
       const { success,data } = u.data;
       if(success){
         this.$store.commit('setUserInfo',data)
         // 然后跳转到之前跳转的页面
         const backurl = this.$store.state.gotoUrl;
         console.log('backurl',backurl)
         if(backurl) setTimeout(()=>  window.location.href = backurl,500)

       }

     },
     isWeiXin(){
      const ua = window.navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        return true;
      }else{
        return false;
      }
    }
  }

}

</script>
<style lang="scss">


</style>
