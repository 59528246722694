import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue')
  },
  {
    path: '/hdList',
    name: 'hdList',
    component: () => import('../views/hdList.vue')
  },

  {  //直播
    path: '/zb',
    name: 'zb',
    component: () => import('../views/zbList.vue')
  },
  { // 志愿
    path: '/zy',
    name: 'zy',
    component: () => import('../views/zy.vue')
  },
  {// 志愿者注册
    path: '/zyreg',
    name: 'zyreg',
    component: () => import('../views/zyreg.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: ()=> import('../views/detail.vue')
  },
  {
    path: '/aclist',
    name: 'aclist',
    component: ()=> import('../views/acList.vue')
  },
  {
    path: '/acdetail',
    name: 'acdetail',
    component: ()=> import('../views/acDetail.vue')
  },
  {
    path:'/search',
    name:'search',
    component: ()=> import('../views/Search.vue')
  },
  {
    path: '/user',
    name: 'user',
    component: ()=> import('../views/User.vue')
  },
  {
    path: '/bookList',
    name: 'bookList',
    component: ()=> import('../views/bookList.vue')
  },
  {
    path: '/bookDetail',
    name: 'bookDetail',
    component: ()=> import('../views/bookDetail.vue')
  },
  {
    path: '/msg',
    name: 'msg',
    component: ()=> import('../views/msg.vue')
  },
  {
    path: '/reg',
    name: 'reg',
    component: ()=> import('../views/reg.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('../views/login.vue')
  },
  {
    path:'/mybook',
    name:'mybook',
    component: ()=> import('../views/mybook.vue')
  },
  {
    path:'/myac',
    name:'myac',
    component: ()=> import('../views/myac.vue')
  },
  {
    path: '/realname',
    name:'realname',
    component: ()=> import('../views/realname.vue')
  },
  {
    path: '/confirmlogin',
    name: 'cfwx',
    component: ()=>import('../views/cfwx')
  },
  {
    path: '/uc',
    name:'uc',
    component: ()=> import('../views/uc.vue')
  },
  {
    path: '/welcom',
    name:'welcom',
    component: ()=> import('../views/welcom.vue')
  }


]

const router = new VueRouter({
  routes
})

export default router
