<template>
  <div class="app home">
    <div class="hd" v-if="false">
      梧州群众艺术馆
      <!--      <div class="ml"> <md-icon name="sort" @click="popright=true"></md-icon> </div>-->
    </div>
    <div class="main">
      <md-scroll-view
          ref="scrollView"
          :scrolling-x="false"
          :auto-reflow="true"
      >


        <div class="swiper">
          <md-swiper
              ref="swiper"
              :is-prevent="false"
              :useNative-driver="false"
              :has-dots="false"
          >
            <md-swiper-item v-for="item of pushData[4]" :key="item.article.aid">
              <div class="swiper-item" @click="$router.push({name:'detail',query:{id:item.article.aid}})">
                <img :src="item.article.cover" style="width: 100%;height: 100%;object-fit: cover" alt="">
                <div >{{item.article.title}}</div>
              </div>
            </md-swiper-item>

          </md-swiper>
        </div>
        <div style="background: #fff;padding:10px 0 0">
          <div class="homeSearch" >
            <md-icon name="search" class="ic"></md-icon>
            <input type="text" v-model="keyword">
            <div class="sbt" @click="dosearch">搜索</div>
          </div>
        </div>

        <div class="navsw">
          <md-swiper
              ref="swiper"
              :is-prevent="false"
              :useNative-driver="false"
              :autoplay="0"
          >

            <md-swiper-item>
              <div class="wrap">
                <div class="item" @click="$router.push({name:'aclist'})">
                  <div class="name">文化活动</div>
                  <div class="ename"> activity</div>
                  <div class="ir">
                    <img :src="p6" alt="">
                  </div>
                </div>
                <div class="item" @click="$router.push({name:'List',query:{cid:111}})">
                  <div class="name">艺术展厅</div>
                  <div class="ename">ArtStation</div>
                  <div class="ir">
                    <img :src="p8" alt="">
                  </div>
                </div>
                <div class="item" @click="$router.push({name:'zy'})">
                  <div class="name">志愿服务</div>
                  <div class="ename"> Venue</div>
                  <div class="ir">
                    <img :src="p2" alt="">
                  </div>
                </div>
              </div>
            </md-swiper-item>
            <md-swiper-item>
              <div class="wrap">
                <div class="item" @click="$router.push({name:'bookList'})">
                  <div class="name">场馆预约</div>
                  <div class="ename"> activity</div>
                  <div class="ir">
                    <img :src="p1" alt="">
                  </div>
                </div>
                <div class="item" @click="$router.push({name:'List',query:{cid:85,big:1}})">
                  <div class="name">馆务公开</div>
                  <div class="ename"> activity</div>
                  <div class="ir">
                    <img :src="p9" alt="">
                  </div>
                </div>
                <div class="item"  @click="$router.push({name:'List',query:{cid:73,big:1}})">
                  <div class="name">文化慕课</div>
                  <div class="ename"> activity</div>
                  <div class="ir">
                    <img :src="p4" alt="">
                  </div>
                </div>
              </div>
            </md-swiper-item>
            <md-swiper-item>
              <div class="wrap">
                <div class="item" @click="$router.push({name:'zb',query:{cid:115}})">
                  <div class="name">直播点播</div>
                  <div class="ename"> brocast</div>
                  <div class="ir">
                    <img :src="p7" alt="">
                  </div>
                </div>
                <div class="item" @click="$router.push({name:'List',query:{cid:97,big:1}})">
                  <div class="name">数字资源</div>
                  <div class="ename"> digital</div>
                  <div class="ir">
                    <img :src="p3" alt="">
                  </div>
                </div>
                <div class="item" @click="$router.push({name:'List',query:{cid:87}})">
                  <div class="name">文化服务</div>
                  <div class="ename">service</div>
                  <div class="ir">
                    <img :src="p9" alt="">
                  </div>
                </div>
              </div>
            </md-swiper-item>


          </md-swiper>

        </div>
        <div class="sfq" >
          <div class="sec active" @click="doac">
            <div class="secd">热点聚焦</div>
            <div class="secs">
              <ul>
                <li v-for="item of pushData[0]" @click="$router.push({name:'detail',query:{id:item.article.aid}})">
                  {{item.article? item.article.title.slice(0,20):''}}
                </li>
              </ul>
            </div>
          </div>
          <div class="sec" @click="doac">
            <div class="secd">活动公告</div>
            <div class="secs">
              <ul>
                <li v-for="item of pushData[1]" @click="$router.push({name:'detail',query:{id:item.article.aid}})">
                  {{item.article? item.article.title.slice(0,20):''}}
                </li>
              </ul>
            </div>
          </div>
          <div class="sec" @click="doac">
            <div class="secd">群文资讯</div>
            <div class="secs">
              <ul>
                <li v-for="item of pushData[2]" @click="$router.push({name:'detail',query:{id:item.article.aid}})">
                  {{item.article? item.article.title.slice(0,20):''}}}
                </li>
              </ul>
            </div>
          </div>
          <div class="sec" @click="doac">
            <div class="secd">文化视窗</div>
            <div class="secs">
              <ul>
                <li v-for="item of pushData[3]" @click="$router.push({name:'detail',query:{id:item.article.aid}})">
                  {{item.article? item.article.title.slice(0,20):''}}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--    群文动态-->
        <div class="sh">
          <div class="title">活动简讯</div>
          <div class="more" @click="$router.push({name:'hdList',query:{cid:'71'}})" >更多</div>
        </div>
        <ul class="qwlist ">
          <li class="it" v-for="(item,index) of hdjx" :key="item.aid+'_'+index">
            <div class="cover">
              <img :src="`${item.cover}-sm`" alt="">
            </div>
            <div class="info">
              <div class="infot">
                <router-link :to="{name:'detail',query:{id:item.aid}}">
                  {{ item.title.slice(0,36) }}
                </router-link>
              </div>
              <div class="infob">
                <div class="author">{{ item.author }}</div>
                <div class="time">{{ item.addTime | format('YYYY-MM-DD') }}</div>
              </div>
            </div>
          </li>
        </ul>

        <!--    党建园地-->
        <div class="djcover" @click="$router.push({name:'List',query:{cid:'63'}})">
          党建园地
        </div>
        <!--    艺术展厅-->
        <div class="sh">
          <div class="title">艺术展厅</div>
          <div class="more" @click="$router.push({name:'List',query:{cid:'111'}})">更多</div>
        </div>
        <ul class="yslist">
          <li class="it" v-for="item of yishuList" :key="item.aid" @click="$router.push({name:'detail',query:{id:item.aid}})">
            <div class="cover">
              <img :src="`${item.cover}-sm`" alt="">
            </div>
            <div class="info">
              <div class="infot">
                {{ item.title }}
              </div>
              <div class="other">
                <div class="add">
                  <!--              地址： {{ item.address }}-->
                  {{item.contentSummary.trim()}}
                </div>
                <div class="t">
                  <!--              时间: 7-15岁-->
                </div>
              </div>

              <div class="infob">
                <div class="author"></div>
                <div class="time">2020-01-11</div>
              </div>
            </div>
          </li>

        </ul>

        <!--    直播-->
        <div class="sh">
          <div class="title">直播/点播</div>
          <div class="more" @click="$router.push({name:'zb',query:{cid:115}})">更多</div>
        </div>
        <div class="playbox">
          <div id="video">
            <video-player   class="video-player-box"
                          ref="videoPlayer"
                          :options="playerOptions"
                          :playsinline="true"
            >
            </video-player>


          </div>
          <div class="playlist">
            <div class="it" v-for="item of zbData.slice(0,3)" @click="play(item)">
              <div class="cover">
                <img :src="`${item.cover}-sm`" alt="">
              </div>
              <div class="title" > {{zbData.zburl?'直播 ':''}} {{ item.title.slice(0,15) }}</div>
            </div>

          </div>
        </div>
        <!--文化慕课-->
        <div class="sh">
          <div class="title">文化慕课</div>
          <div class="more" @click="$router.push({name:'List',query:{cid:73}})">更多</div>
        </div>
        <div class="aclist">
          <div class="it" v-for="item of whmk" :key="item.id" @click="$router.push({name:'detail',query:{id:item.aid}})">
            <div class="cover">
              <img :src="`${item.cover}-sm`" alt="">
            </div>
            <div class="info">
              <div class="title">{{item.title}}</div>
              <div class="t">时间: {{ item.addTime | format('YYYY-MM-DD') }}</div>
              <div class="t"><md-icon name="visible" /> {{item.reading}}</div>

            </div>

          </div>
        </div>

        <!--    活动推荐-->
        <div class="sh">
          <div class="title">活动推荐</div>
          <div class="more" @click="$router.push({name:'aclist'})">更多</div>
        </div>
        <div class="aclist">
          <div class="it" v-for="item of activity" :key="item.id" @click="$router.push({name:'acdetail',query:{id:item.id}})">
            <div class="cover">
              <img :src="item.cover" alt="">
            </div>
            <div class="info">
              <div class="title">{{item.title.slice(0,24)}}</div>
              <div class="t">时间: {{ item.sTime | format('YYYY-MM-DD') }}</div>
              <div class="t">地址: 梧州市群众艺术馆</div>
              <div class="detail">
              <span>
                <template v-if="item.state == 1" @click="joinac"> 立即预约</template>
                <template v-else-if="item.state == 0"> 即将开放预约</template>
                <div v-else-if="item.state == 2"> 进行中</div>
                <template v-else-if="item.state == 3" @click="joinac"> 已结束</template>
                <template v-else-if="item.state == 5"> 即将开始</template>
                <template v-else>等待中</template>
              </span>
                <span>0/{{ item.peopleLimit }}</span>
              </div>
            </div>

          </div>

        </div>
        <!--    友情链接-->
        <div class="sh">
          <div class="title">友情链接</div>
          <div class="more"></div>
        </div>
        <div class="links">
          <md-scroll-view scrolling-x :scrolling-y="false" auto-reflow>
            <div class="wrap">
              <div class="item" v-for="item of links">
                <div class="cover">
                  <img :src="item.imgurl" alt="">
                </div>
                <div class="title">{{ item.name }}</div>
              </div>

            </div>
          </md-scroll-view>



        </div>
      </md-scroll-view>
      <navSlide />
    </div>


  </div>
</template>

<script>
import p1 from '@/assets/img/场馆预约.png'
import p2 from '@/assets/img/志愿服务.png'
import p3 from '@/assets/img/digi.png'
import p4 from '@/assets/img/文化慕课.png'
import p5 from '@/assets/img/文化服务.png'
import p6 from '@/assets/img/文化活动.png'
import p7 from '@/assets/img/直播.png'
import p8 from '@/assets/img/艺术展厅.png'
import p9 from '@/assets/img/馆务公开.png'
import flvjs from 'flv.js'
export default {
  name: 'Home',
  data(){
    return {
      haszb:false,
      p1,p2,p3,p4,p5,p6,p7,p8,p9,
      pushData:[[],[],[],[],[]],
      activity:[],
      book:[],
      links:[],
      siteInfo:{},
      yishuList:[],
      popright:false,
      category:[],
      keyword:'',
      zbData:[],
      playerOptions: {
        muted: true,
        autoplay: false, //如果true,浏览器准备好时开始回放。
        language: 'zh',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "application/x-mpegURL",
          src: "http://home.yiker.cc:8000/live/wz/index.m3u8"
          // src: "rtmp://8.135.85.155:8881/hls/500868544"
        }],
      },
      whmk:[],
      hdjx:[]
    }
  },
  mounted(){
    this.getIndexData()
    this.getYishu()
    this.getzbdata()
  },
  methods:{
    async getzbdata(){
      let res = await this.$api.post('/api/getzbdata')
      const { success ,data} = res.data;
      if(success) this.zbData = data.slice(0,3)

        this.haszb = true

      this.play(this.zbData[0])
    },
    async dosearch(){

      this.$router.push({name:'search',query:{keyword:this.keyword}})
    },
    async getYishu(){

      let res = await this.$api.post('/mapi/acticleList',{cid:111,page:1,limit:3})
      const  { count,rows,success}  = res.data;
      this.yishuList = rows
    },
    async getIndexData(){
      const res = await this.$api.post('/one/api/getOneIndex',{platform:2});
      const { success,pushData,activity ,book,links,category,whmk,hdjx} = res.data;
      console.log('pd',pushData)
      if(success){
        this.pushData = pushData;
        this.activity = activity;
        this.book = book;
        this.links = links
        this.category = category
        this.whmk   =whmk
        this.hdjx = hdjx // 0423 by dixon

      }
    },
    doac(ev){
      document.querySelector('.sfq .active').classList.remove('active')
      ev.currentTarget.classList.add('active')
    },
    play(item){
      if(item.zburl){
        this.haszb = true;
        this.$nextTick(()=>{
          this.playerOptions = {
            muted: true,
            language: 'zh',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [{
              type: "application/x-mpegURL",
              src: "http://home.yiker.cc:8000/live/wz/index.m3u8"
              // src: "rtmp://8.135.85.155:8881/hls/500868544"
            }],
          }
        })

      }else{
        this.haszb = false
        this.$nextTick(()=>{
          this.playerOptions = {
            muted: true,
            language: 'zh',
            autoplay: false, //如果true,浏览器准备好时开始回放。
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [{
              type: "video/mp4",
              src: item.video
              // src: "rtmp://8.135.85.155:8881/hls/500868544"
            }],
          }
        })

      }


    }
  }

}
</script>
<style lang="scss" scoped>
.home{
  background: #f4f4f4;
  height: 100%;
}
.main{
  height: 0;
  flex-grow: 1;
}
.swiper{
  height: 350px;
  .swiper-item{
    height: 100%;
    position: relative;
    div{
      position: absolute;
      line-height: 60px;
      background: rgba(0,0,0,.5);
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      bottom:0;
      left:0;
      font-size: 20px;
      color:#fff;
      padding-left: 5px;
    }
  }
}
.navsw{
  background: #fff;
  padding:20px 0;
  .wrap{
    display: flex;
    justify-content: space-between;
    padding:20px
  }
  .item{
    width: 230px;
    height: 150px;
    border: 1px solid #d0d0d0;
  }
  ::v-deep{
    .md-swiper-indicators{
      bottom:-10px
    }
    .md-swiper-indicator{
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
    .md-swiper-indicator-active{
      background: #ff6600;
    }
  }
}
.sfq{
  height: 210*2px;
}
::v-deep .video-js{
  height: 100%;
  width: 100%;

  .vjs-big-play-button{
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
  }

}

</style>
