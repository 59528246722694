import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Hd from './components/Hd'
import comhd from './components/comhd'
import navSlide from './components/navSlide'
import axios from "axios";
Vue.component('Hd',Hd)
Vue.component('comhd',comhd)
Vue.component('navSlide',navSlide)
import mandMobile from 'mand-mobile'
import 'mand-mobile/lib-vw/mand-mobile.css'
import dayjs from 'dayjs'
Vue.use(mandMobile)

import 'vue-loaders/dist/vue-loaders.css';

import VueVideoPlayer from 'vue-video-player'
import 'videojs-contrib-hls'

// require videojs style
import 'video.js/dist/video-js.css'

// add plugin
import VueLoaders from 'vue-loaders';
// add plugin
Vue.use(VueLoaders);
import VueAliplayerV2 from 'vue-aliplayer-v2';

Vue.use(VueAliplayerV2);


Vue.use(VueVideoPlayer)

Vue.prototype.$api = axios.create({
  // baseURL: 'http://127.0.0.1:8001',
  // withCredentials:true
  baseURL: 'https://www.wzsqyg.com'
})

Vue.mixin({
  data(){
    return {
      popright:false
    }
  },
  filters:{
    format(t,st){
      let d = new Date(t);
      return dayjs(d).format(st)
    },
    htmlfilter: function (val) {
      if(!val) return val
      return val.replace(/<[^>]*>/g,'');
    },
    actypeStr(res){
      const _type = [
        { value: 1, label: '展览' },
        { value: 2, label: '赛事' },
        { value: 3, label: '培训' },
        { value: 4, label: '演出' },
        { value: 5, label: '讲座' },
        { value: 6, label: '公益' },
      ];
      return _type.find(r => r.value == res) ?  _type.find(r => r.value == res).label:'获取中';
    },
    bookTypeStr(res){
      const _type = [
        { value: 1, label: '舞蹈室' },
        { value: 2, label: '音乐室' },
        { value: 3, label: '画室' },
        { value: 4, label: '展示厅' },
        { value: 5, label: '录音室' },
        { value: 6, label: '其他' },
      ];
      return _type.find(r => r.value == res) ?  _type.find(r => r.value == res).label:'获取中';

    },
    zoneStr(res){
      const _zoneList = [
        { value: 1, label: '万秀区' },
        { value: 2, label: '长洲区' },
        { value: 3, label: '龙圩区' },
        { value: 4, label: '苍梧县' },
        { value: 5, label: '藤县' },
        { value: 6, label: '蒙山县' },
        { value: 7, label: '岑溪市' },
      ];
      return _zoneList.find(r => r.value == res).label;
    }
  },
  methods:{
    isWeiXin(){
      const ua = window.navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        return true;
      }else{
        return false;
      }
    }
  }
})

Vue.config.productionTip = false
import "./global.scss";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
