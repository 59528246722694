import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[createPersistedState()],
  state: {
    userInfo:{
      uid:'',
      unionid:'',
      heardPhoto:'',
      userName:'',
      phone:'',
      wxNickName:'',
      unionId:'',
      state:''
    },
    gotoUrl:''
  },
  mutations: {
    setUserInfo(state,res){
      state.userInfo = {...state,...res}
    },
    seturl(state,res){
      state.gotoUrl = res;
    }
  },
  actions: {
  },
  modules: {
  }
})
