<template>
<div style="position: fixed;z-index: 2999">
  <md-popup
      v-model="popright"
      position="right"
  >
    <div class="homeLeft">
      <div class="uct">
        <div class="avatar">
          <img :src="$store.state.userInfo.heardPhoto" alt="" v-if="$store.state.userInfo.heardPhoto">
          <img src="https://fakeimg.pl/300/"  v-else />
        </div>
        <div class="name">

            <template v-if="$store.state.userInfo.userName">  {{$store.state.userInfo.userName}}   </template>
            <template v-else-if="$store.state.userInfo.phone">  {{$store.state.userInfo.phone}}  </template>
            <template v-else-if="$store.state.userInfo.wxNickName">  {{$store.state.userInfo.wxNickName}}  </template>
            <template v-else>
              游客
              <div>
                <span @click="$router.push({name:'login'})">登录</span>
                <span @click="$router.push({name:'reg'})" style="margin-left: 15px;">注册</span>
              </div>

            </template>

<!--          微信用户没有登出-->
          <template v-if="$store.state.userInfo.uid && !iswx">
             <div style="line-height: 2;" @click="dologout">退出登录</div>
          </template>


        </div>
      </div>
      <div class="uclist">
        <div @click="goHome">首页</div>
        <div @click="$router.push({name:'bookList'})"> 场馆预约 </div>
        <div @click="$router.push({name:'zb'})">直播</div>
        <div @click="$router.push({name:'zy'})">志愿服务</div>
        <div @click="$router.push({name:'aclist'})">文化活动</div>
        <div v-for="item of category.filter(r=>r.name!=='场馆预约')" :key="item.cid" @click="$router.push({name:'List',query:{cid:item.cid}})">
          {{item.name}}
        </div>

        <div v-if="$store.state.userInfo.uid" @click="$router.push({name:'uc'})">用户中心</div>

      </div>
    </div>
  </md-popup>
  <div class="navicon" @click="popright=!popright">
    <div class="avt">
      <img :src="$store.state.userInfo.heardPhoto || dz" alt="">
    </div>

    <div>更多板块</div>
  </div>
  <md-landscape v-model="showPic">
    <img src="../assets/img/wxqcode.jpg">
  </md-landscape>
</div>
</template>

<script>
import dz from '@/assets/img/dz.jpg'
export default {
name: "navSlide",
  data(){
    return {
      dz,
      category:[
        {name:'馆务公开',cid:85},
        {name:'文化慕课',cid:73},
        {name:'文艺展厅',cid:111},


      ],
      showPic:false
    }
  },
  created(){
    this.getIndexData()
    let iswx = this.isWeiXin();
    this.showPic = iswx ? false:true;
  },
  computed:{
    iswx(){
      return this.isWeiXin()
    }
  },
  methods:{
    goHome(){
      window.location.href='/'
    },
    async getIndexData(){



      // const res = await this.$api.post('/one/api/getOneIndex',);
      // const { success,pushData,activity ,book,links,category,whmk} = res.data;
      //
      // if(success){
      //   this.category = category.filter(r=> r.name!=='直播'&&r.name!=='文化活动'&&r.name!=='志愿服务' )
      // }
    },
    dologout(){
      this.$store.commit('setUserInfo',{
        uid:'',
        unionid:'',
        heardPhoto:'',
        userName:'',
        phone:'',
        wxNickName:'',
        unionId:'',
        state:''
      })
    }
  }
}
</script>

<style scoped lang="scss">
.navicon{
  width: 100px;
  position: fixed;
  right:20px;
  bottom:300px;
  z-index: 99999;
  img{
    width: 100%;
  }
  div{
    line-height: 40px;
    font-size: 20px;
  }
}
::v-deep{
  .md-popup-box{
    z-index: 9999999;
  }
}
.avt{
  width: 100px;
  height: 100px;
  overflow:hidden;
  border-radius: 50%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
